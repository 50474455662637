exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-auth-reset-password-tsx": () => import("./../../../src/pages/auth/reset_password.tsx" /* webpackChunkName: "component---src-pages-auth-reset-password-tsx" */),
  "component---src-pages-auth-signin-tsx": () => import("./../../../src/pages/auth/signin.tsx" /* webpackChunkName: "component---src-pages-auth-signin-tsx" */),
  "component---src-pages-auth-token-tsx": () => import("./../../../src/pages/auth/token.tsx" /* webpackChunkName: "component---src-pages-auth-token-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-dashboard-create-profile-tsx": () => import("./../../../src/pages/dashboard/createProfile.tsx" /* webpackChunkName: "component---src-pages-dashboard-create-profile-tsx" */),
  "component---src-pages-dashboard-index-tsx": () => import("./../../../src/pages/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-participate-tsx": () => import("./../../../src/pages/participate.tsx" /* webpackChunkName: "component---src-pages-participate-tsx" */),
  "component---src-templates-survey-template-tsx": () => import("./../../../src/templates/SurveyTemplate.tsx" /* webpackChunkName: "component---src-templates-survey-template-tsx" */)
}

