import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuth, signOut } from 'firebase/auth';
import { getDoc, doc, getDocs, setDoc, updateDoc } from 'firebase/firestore';

import type { ProfileData } from '../profileSlice';
import { getUid } from './helper';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAnalytics, logEvent } from 'firebase/analytics';
import {
  getSurveyProgressCollection,
  triggerWorkflowCompletedSurvey,
  userDetailsCollection,
  userJourneyCollection
} from 'firebaseApp';
import { SurveyProgress, UserDetails } from '@vega/common';

export const createProfile = createAsyncThunk('profile/create', async (userProfile: UserDetails) => {
  const auth = getAuth();
  const { uid } = auth.currentUser!;
  await setDoc(doc(userDetailsCollection, uid), userProfile);
  const userJourneyDoc = await getDoc(doc(userJourneyCollection, uid));
  if (!userJourneyDoc.exists()) {
    throw new Error('User Journey data not found');
  }
  const userJourneyData = userJourneyDoc.data()!;
  return { user: userProfile, userJourneyData };
});

export const fetchProfile = createAsyncThunk('profile/fetch', async (): Promise<ProfileData> => {
  const auth = getAuth();

  const { uid, displayName, email, photoURL } = auth.currentUser!;

  const userDoc = await getDoc(doc(userDetailsCollection, uid));
  if (!userDoc.exists()) {
    throw new Error('User profile not found');
  }

  const userJourneyDoc = await getDoc(doc(userJourneyCollection, uid));
  if (!userJourneyDoc.exists()) {
    throw new Error('User Journey data not found');
  }
  const userJourneyData = userJourneyDoc.data()!;

  const user = { ...userDoc.data(), uid, email, displayName, photoURL };

  const surveySnapshot = await getDocs(getSurveyProgressCollection(uid));
  const surveys = surveySnapshot.docs.reduce<Record<string, SurveyProgress>>((surveys, doc) => {
    return { ...surveys, [doc.id]: doc.data() as SurveyProgress };
  }, {});

  return { surveyProgress: surveys, user, userJourneyData };
});

export const updateProfile = createAsyncThunk('profile/update-user', async (data: Partial<UserDetails>) => {
  const uid = getUid();
  await updateDoc(doc(userDetailsCollection, uid), data);
  return data;
});

export const revokeConsent = createAsyncThunk('profile/revoke-consent', async () => {
  const auth = getAuth();
  const analytic = getAnalytics();
  const functions = getFunctions();
  const revokeConsentFn = httpsCallable(functions, 'revokeConsent');
  await revokeConsentFn();
  await signOut(auth);
  logEvent(analytic, 'revoke');
});

export const advanceUserWorkflow = createAsyncThunk('profile/current-state', async (surveyKey: string) => {
  const result = await triggerWorkflowCompletedSurvey({ surveyKey });
  return result.data;
});