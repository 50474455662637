interface ThrottleInstance<Args, Result> {
  lastInvokedMs: number;
  lastArgs: Args;
  throttled?: Promise<Result>;
}

/**
 * Wrapper for an async function which ensures that it won't be invoked too frequently. Throttled invocations return
 * the same promise, which will wait up to `waitMs` milliseconds and then be invoked with the last parameters passed
 * to the function.
 * @param waitMs Number of milliseconds to wait before allowing the function to be invoked again.
 * @param func The async function to throttle.
 * @param argsToId Optional function to return a "throttle ID".  Invocations of `func` with the same throttle ID are
 * throttled against one another, while invocations with different throttle IDs are independent. If omitted, all
 * invocations of `func` are throttled against one another.
 */
export default function asyncThrottle<T extends (...args: any[]) => Promise<R>, R extends any>(
  waitMs: number,
  func: T,
  argsToId?: (...args: Parameters<T>) => string
): ((...args: Parameters<T>) => Promise<R>) {
  const instances: {[id: string]: ThrottleInstance<Parameters<T>, R>} = {};

  return async (...args: Parameters<T>): Promise<R> => {
    const id = argsToId?.(...args) ?? 'default';
    if (!instances[id]) {
      instances[id] = {
        lastInvokedMs: 0,
        lastArgs: args,
      };
    }
    const msSinceLastInvoked = Date.now() - instances[id].lastInvokedMs;
    if (msSinceLastInvoked >= waitMs) {
      instances[id].lastInvokedMs = Date.now();
      return func(...args);
    } else {
      instances[id].lastArgs = args;
      if (!instances[id].throttled) {
        instances[id].throttled = new Promise((resolve) => {
          setTimeout(() => {
            instances[id].lastInvokedMs = Date.now();
            instances[id].throttled = undefined;
            resolve(func(...instances[id].lastArgs));
          }, waitMs - msSinceLastInvoked);
        });
      }
      return instances[id].throttled!;
    }
  }
}