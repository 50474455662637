import {
  getAuth,
  signOut,
  sendPasswordResetEmail,
  UserInfo,
  updatePassword,
  updateProfile,
} from 'firebase/auth';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const resetPassword = createAsyncThunk('auth/resetPassword', async (email: string): Promise<void> => {
  const auth = getAuth();
  const analytics = getAnalytics();
  await sendPasswordResetEmail(auth, email);
  logEvent(analytics, 'reset_password');
});

export const signout = createAsyncThunk('auth/signout', async (): Promise<void> => {
  const auth = getAuth();
  await signOut(auth);
});

export const changePassword = createAsyncThunk('auth/changePassword', async (newPassword: string): Promise<void> => {
  const auth = getAuth();
  await updatePassword(auth.currentUser!, newPassword);
});

export const updatePhotoURL = createAsyncThunk(
  'auth/updatePhoto',
  async (photoURL: string): Promise<Partial<UserInfo>> => {
    const auth = getAuth();
    await updateProfile(auth.currentUser!, { photoURL });
    return { photoURL };
  },
);
