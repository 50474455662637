import './firebaseApp';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { store } from 'app/store';
import { loadAuth } from 'app/store';
import theme from './theme';
import './global.css';
import { CommonReactThemeProvider } from '@vega/common-react';

import type { ReactNode } from 'react';

export type PageRootProps = {
  children: ReactNode;
};

export default function PageProvider(props: PageRootProps) {
  useEffect(() => {
    return loadAuth(store);
  }, []);

  return (
    <Provider store={store}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <CommonReactThemeProvider theme={theme}>
          {props.children}
        </CommonReactThemeProvider>
      </ThemeProvider>
    </Provider>
  );
}
