import { createAsyncThunk } from '@reduxjs/toolkit';
import { doc, getFirestore, Timestamp, writeBatch, updateDoc, getDoc } from 'firebase/firestore';
import { getSurveyProgressCollection, userJourneyCollection } from 'firebaseApp';
import { getSurveyIdFromStateMeta, SurveyProgress, UserSurveyProgressEnum } from '@vega/common';
import { getUid } from './helper';
import asyncThrottle from '../../helpers/asyncThrottle';

/**
 * Start a survey or optionally mark start of a stage
 */
export const startSurvey = createAsyncThunk('survey/start', async () => {
  const firestore = getFirestore();
  const uid = getUid();

  const userJourneyRef = doc(userJourneyCollection, uid);
  const userJourneyDoc = await getDoc(userJourneyRef);
  const currentState = userJourneyDoc.data()?.currentState;
  const surveyKey = getSurveyIdFromStateMeta(currentState);
  if (!surveyKey) {
    const error = `User ${uid} cannot start survey: no surveyKey associated with current state ${currentState}.`;
    console.error(error);
    throw new Error(error);
  }

  const batch = writeBatch(firestore);

  batch.update(userJourneyRef, {
    surveyProgress: {
      ...userJourneyDoc.data()?.surveyProgress,
      [surveyKey]: UserSurveyProgressEnum.Started,
    },
  });

  const data: SurveyProgress = {
    _total: 0,
    _completed: 0,
    _step: 0,
    _startedTimestamp: Timestamp.fromDate(new Date()),
  };
  const surveyCollection = getSurveyProgressCollection(uid);
  batch.set(doc(surveyCollection, surveyKey), data);

  await batch.commit();

  return {
    id: surveyKey,
    data,
  };
});

/**
 * Mark a survey as completed (set the finished timestamp)
 */
export const finishSurvey = createAsyncThunk('profile/finishSurvey', async () => {
  // Mark the current survey as completed
  const uid = getUid();
  const userJourneyRef = doc(userJourneyCollection, uid);
  const userJourneyDoc = await getDoc(userJourneyRef);
  const currentState = userJourneyDoc.data()?.currentState;
  const surveyKey = getSurveyIdFromStateMeta(currentState);
  if (!surveyKey) {
    const error = `User ${uid} cannot finish survey: no surveyKey associated with current state ${currentState}.`;
    console.error(error);
    throw new Error(error);
  }

  const firestore = getFirestore();
  const batch = writeBatch(firestore);

  batch.update(userJourneyRef, {
    surveyProgress: {
      ...userJourneyDoc.data()?.surveyProgress,
      [surveyKey]: UserSurveyProgressEnum.Completed,
    },
  });

  const surveyCollection = getSurveyProgressCollection(uid);
  const progressRef = doc(surveyCollection, surveyKey);
  const progressSnapshot = await getDoc(progressRef);
  const update: Partial<SurveyProgress> = { _completedTimestamp: Timestamp.fromDate(new Date()) };
  batch.update(progressRef, {
    ...progressSnapshot.data(),
    ...update,
  });

  await batch.commit();

  return {
    id: surveyKey,
    data: update,
  };
});

const updateSurveyHelper = async (id: string, data: Partial<SurveyProgress>) => {
  const uid = getUid();
  const surveyCollection = getSurveyProgressCollection(uid);
  await updateDoc(doc(surveyCollection, id), data);
  return { id, data };
};

// Throttle invocations of updateSurveyHelper, as long as they're for the same surveyKey and fields of the
// SurveyProgress.
const throttledUpdateDoc = asyncThrottle(
  1000,
  updateSurveyHelper,
  (id, data) => `${id}-${Object.keys(data).join('-')}`,
);

export const updateSurvey = createAsyncThunk(
  'profile/updateSurvey',
  async ({ id, data }: { id: string; data: Partial<SurveyProgress> }) => {
    return throttledUpdateDoc(id, data);
  },
);
