import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator, collection } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import {
  ContractTypes,
  getSurveyProgressCollectionDetails,
  getSurveyRecommendationCollectionDetails,
  getUserFeedbackCollectionDetails,
  getUserDetailsCollectionDetails,
  getUserJourneyCollectionDetails,
} from '@vega/common';

const config: { [key: string]: any } = {
  apiKey: process.env.GATSBY_FIREBASE_APIKEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTHDOMAIN,
  projectId: process.env.GATSBY_FIREBASE_PROJECTID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APPID,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENTID,
};

for (const key in config) {
  if (!config[key]) {
    throw new Error(`Firebase has not been configured for ${process.env.NODE_ENV} for ${key}`);
  }
}

const app = initializeApp(config);

if (process.env.GATSBY_USE_EMULATOR) {
  console.info('web: using emulator...');
  connectFirestoreEmulator(getFirestore(app), 'localhost', 8080);
  connectFunctionsEmulator(getFunctions(app), 'localhost', 5001);
  connectAuthEmulator(getAuth(app), 'http://127.0.0.1:9099', { disableWarnings: true });
}

export { app };

const userJourneyCollectionDetails = getUserJourneyCollectionDetails();
export const userJourneyCollection = collection(getFirestore(app), userJourneyCollectionDetails.path).withConverter(
  userJourneyCollectionDetails.converter,
);

export const getSurveyProgressCollection = (userId: string) => {
  const collectionDetails = getSurveyProgressCollectionDetails(userId);
  return collection(getFirestore(app), collectionDetails.path).withConverter(collectionDetails.converter);
};

const userDetailsCollectionDetails = getUserDetailsCollectionDetails();
export const userDetailsCollection = collection(getFirestore(app), userDetailsCollectionDetails.path).withConverter(
  userDetailsCollectionDetails.converter,
);

export const getSurveyRecommendationCollection = (userId: string, surveyKey: string, navigation: boolean) => {
  const details = getSurveyRecommendationCollectionDetails(userId, surveyKey, navigation);
  return collection(getFirestore(app), details.path).withConverter(details.converter);
};

export const getUserFeedbackCollection = (userId: string, surveyKey: string) => {
  const details = getUserFeedbackCollectionDetails(userId, surveyKey);
  return collection(getFirestore(app), details.path).withConverter(details.converter);
};

const makeCallable = <CallableName extends keyof ContractTypes>(callableName: CallableName) =>
  httpsCallable<ContractTypes[CallableName]['input'], ContractTypes[CallableName]['output']>(
    getFunctions(app),
    callableName,
  );

export const sendSignInLink = makeCallable('sendSignInLink');
export const getSignInToken = makeCallable('getSignInToken');
export const triggerWorkflowCompletedSurvey = makeCallable('triggerWorkflowCompletedSurvey');
