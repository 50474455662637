import update from 'immutability-helper';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Timestamp } from 'firebase/firestore';
import { authenticated } from './authSlice';
import { fetchProfile, createProfile, updateProfile, advanceUserWorkflow } from './thunks/userThunks';
import { updateSurvey, startSurvey, finishSurvey } from './thunks/surveyThunks';
import { updatePhotoURL } from './thunks/authThunks';
import { MachineStates, rctMachine, SurveyProgress, UserDetails, UserJourney } from '@vega/common';

export type ProfileData = {
  user: UserDetails;
  surveyProgress: Record<string, SurveyProgress>;
  userJourneyData: UserJourney;
};

export type ProfileState = {
  loaded: boolean;
  data: ProfileData | null;
  error?: true;
  saving: number;
};

export const initialState: ProfileState = {
  loaded: false,
  data: null,
  saving: 0
};

const { reducer, actions } = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    markLocalCompleted(state, action: PayloadAction<{ scope: string }>) {
      const { scope } = action.payload;
      return update(state, {
        data: {
          user: {
            $merge: { [`${scope}CompletedAt`]: Timestamp.now() },
          },
        },
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authenticated, (state, action) => {
        return action.payload === null ? update(state, { $set: initialState }) : state;
      })
      .addCase(startSurvey.fulfilled, (state, action) => {
        const { id, data } = action.payload;
        return update(state, {
          data: {
            surveyProgress: { [id]: { $set: data } },
          },
        });
      })
      .addCase(updateSurvey.pending, (state, action) => {
        const { id, data } = action.meta.arg;
        return update(state, {
          data: {
            surveyProgress: {
              [id]: { $merge: data },
            },
          },
          saving: { $set: state.saving + 1 },
        });
      })
      .addCase(updateSurvey.rejected, (state) => {
        state.error = true;
        state.saving--;
      })
      .addCase(updateSurvey.fulfilled, (state) => {
        state.error = undefined;
        state.saving--;
      })
      .addCase(finishSurvey.fulfilled, (state, action) => {
        const { id, data } = action.payload;
        return update(state, {
          data: {
            surveyProgress: { [id]: { $merge: data } },
          },
        });
      })

      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loaded = true;
      })
      .addCase(fetchProfile.rejected, (state) => {
        state.loaded = false;
      })
      .addCase(createProfile.fulfilled, (state, action) => {
        state.data = {
          user: action.payload.user,
          surveyProgress: {},
          userJourneyData: action.payload.userJourneyData,
        };
        state.loaded = true;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        return update(state, {
          data: {
            user: {
              $merge: action.payload,
            },
          },
        });
      })
      .addCase(updatePhotoURL.fulfilled, (state, action) => {
        return update(state, {
          data: {
            user: {
              $merge: action.payload,
            },
          },
        });
      })
      .addCase(advanceUserWorkflow.fulfilled, (state, action) => {
        // Redux toolkit has immer built in, and `state` is an immer WritableDraft, so mutations will be converted
        // into non-mutating updates automatically.
        const { currentState } = action.payload;
        if (currentState && state.data && currentState in rctMachine.states) {
          state.data.userJourneyData.currentState = currentState as MachineStates;
        }
      });
  },
});

export { reducer, actions };
