import { SimplePaletteColorOptions } from '@mui/material';
import { createTheme } from '@mui/material';
import { theme } from '@vega/common-react';

// For whatever reason, "info" is omitted as a valid color value for
// `AppBar` by default - so we add it here
declare module '@mui/material/AppBar' {
  interface AppBarPropsColorOverrides {
    info: true;
  }
}

declare module '@mui/material/FormLabel' {
  interface FormLabelPropsColorOverrides {
    checkInSurvey: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    violet: SimplePaletteColorOptions;
    checkInSurvey: SimplePaletteColorOptions;
    seafoam: SimplePaletteColorOptions;
    gray: SimplePaletteColorOptions;
    skyBlue: SimplePaletteColorOptions;
    carnation: SimplePaletteColorOptions;
  }
  interface PaletteOptions {
    violet: SimplePaletteColorOptions;
    checkInSurvey: SimplePaletteColorOptions;
    seafoam: SimplePaletteColorOptions;
    gray: SimplePaletteColorOptions;
    skyBlue: SimplePaletteColorOptions;
    carnation: SimplePaletteColorOptions;
  }
}

export default createTheme(theme);
